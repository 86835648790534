import PropTypes from "prop-types"
import React from "react"
import NewsletterForm from "./newsletter-form"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="footer">
      <div className="footer__inner">
        <p>© LBD STUDIOS</p>
        <div className="footer__links">
          <ul>
            <li><Link to="https://instagram.com/lbd.studios">Instagram</Link></li>
            <li><Link to="https://www.linkedin.com/company/lbd-studios/">LinkedIn</Link></li>         
          </ul>
          <NewsletterForm />
        </div>
      </div>
    </div>

  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer