import React, {Component} from 'react';

function encode(data) {
  return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

class NewsletterForm extends Component {

  state = {
    result: '',
    msg: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("https://hooks.zapier.com/hooks/catch/3298056/2zxqq8e/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success', msg: 'Thank you for subscribing!'}))
      .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'subscribe',
      className: 'form',
      onSubmit: this.handleSubmit
    };
    if (this.state.result === 'success') {
      return (
        <div className='form__thanks'>
          <p className="result" dangerouslySetInnerHTML={{ __html: this.state.msg }} />
        </div>
      )
    }
    return (
      <form {...props}>
        <input
          type="email"
          name="email"
          placeholder="Subscribe to Newsletter"
          onChange={this.handleChange}
          required
        />
        <button 
          type="submit" 
          className="btn"
          >
          <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.064 3.424L5.148 0.508L5.628 0.0399995L9.36 3.772L5.628 7.504L5.136 7.036L8.064 4.12H0.468V3.424H8.064Z" fill="white"/>
          </svg>
        </button>
      </form>
    );
  }
}

export default NewsletterForm